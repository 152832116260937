import { http } from "@/utils/http";
import type { respones } from "./apiTypes";

export const login = data => {
  return http.request("post", "/admin/login", { data });
};

export function changeBonus(id: number, data: any) {
  return http.request<respones>("post", `/admin/user/bonus/${id}`, {
    data
  });
}

export function getUserList(params: any) {
  return http.request<respones>("get", "/admin/user", { params });
}

export function editUserDetail(id, data) {
  return http.request("post", `/admin/user/edit/${id}`, {
    data
  });
}

// 管理员-获取管理员列表
export function getAdminList() {
  return http.request<respones>("get", "/admin/admin");
}
// 管理员-删除管理员
export function destroyAdmin(id, data) {
  return http.request<respones>("post", `/admin/destroy/${id}`, {
    data
  });
}
// // 管理员-创建管理员
export function addAdmin(data) {
  return http.request<respones>(
    "post",
    data.id ? `/admin/admin/${data.id}` : `/admin/admin`,
    {
      data
    }
  );
}

// 管理权限列表
export function getAuthGroup() {
  return http.request<respones>("get", `/admin/authGroup`);
}

// /** 刷新`token` */
// export const refreshTokenApi = (data?: object) => {
//   return http.request<any>("post", "/refresh-token", { data });
// };

// 修改用户洗码量
export function editWashMoney(data) {
  return http.request<respones>("post", `/admin/user/water/change/${data.id}`, {
    data
  });
}

// 修改用户上级代理
export function editSuperiorId(data) {
  return http.request<respones>("post", `/admin/user/agent/change/${data.id}`, {
    data
  });
}

// 修改提现用户密码
export function editUserPass(data) {
  return http.request<respones>(
    "post",
    `/admin/user/update/withdrawal-password`,
    {
      data
    }
  );
}

// 修改用户登录密码
export function editLoginPas(data) {
  return http.request<respones>("post", `/admin/user/update/login-password`, {
    data
  });
}

// 上下分查询用户
export function searchUser(data) {
  return http.request<respones>("post", `/admin/hand/search-user`, {
    data
  });
}

// 上下分记录
export function handList(params) {
  return http.request<respones>("get", `/admin/hand/hand-record`, {
    params
  });
}
