import Cookies from "js-cookie";
import { useUserStoreHook } from "@/store/modules/user";

export interface DataInfo {
  // 这里面存放
  result?: any;
}

export const TokenKey = "token";
/**
 * 通过`multiple-tabs`是否在`cookie`中，判断用户是否已经登录系统，
 * 从而支持多标签页打开已经登录的系统后无需再登录。
 * 浏览器完全关闭后`multiple-tabs`将自动从`cookie`中销毁，
 * 再次打开浏览器需要重新登录系统
 * */
export const multipleTabsKey = "multiple-tabs";

/** 获取`token` */
export function getToken(): String {
  return Cookies.get(TokenKey);
}

/**  按字段获取`getCookie` */
export function getCookie(key: string): String {
  return Cookies.get(key);
}

/**
 * @description 设置`token`以及一些必要信息并采用无感刷新`token`方案
 * 无感刷新：后端返回`accessToken`（访问接口使用的`token`）、`refreshToken`（用于调用刷新`accessToken`的接口时所需的`token`，`refreshToken`的过期时间（比如30天）应大于`accessToken`的过期时间（比如2小时））、`expires`（`accessToken`的过期时间）
 * 将`accessToken`、`expires`、`refreshToken`这三条信息放在key值为authorized-token的cookie里（过期自动销毁）
 * 将`avatar`、`username`、`nickname`、`roles`、`refreshToken`、`expires`这六条信息放在key值为`user-info`的localStorage里（利用`multipleTabsKey`当浏览器完全关闭后自动销毁）
 */
export function setToken(data: DataInfo) {
  const { access_token, refresh_token, expires_in, token_type } = data.result;
  const { isRemembered, loginDay } = useUserStoreHook();

  // const cookieString = JSON.stringify({ access_token, expires, refresh_token });

  Cookies.set("token", access_token);
  Cookies.set("token_type", token_type);
  Cookies.set("refresh_token", refresh_token);
  Cookies.set("expires_in", expires_in);

  Cookies.set(
    multipleTabsKey,
    "true",
    isRemembered
      ? {
          expires: loginDay
        }
      : {}
  );

  // function setUserKey({ avatar, username, nickname, roles }) {
  //   useUserStoreHook().SET_AVATAR(avatar);
  //   useUserStoreHook().SET_USERNAME(username);
  //   useUserStoreHook().SET_NICKNAME(nickname);
  //   useUserStoreHook().SET_ROLES(roles);
  //   storageLocal().setItem(userKey, {
  //     refresh_token,
  //     expires,
  //     avatar,
  //     username,
  //     nickname,
  //     roles
  //   });
  // }

  // if (data.username && data.roles) {
  //   const { username, roles } = data;
  //   setUserKey({
  //     avatar: data?.avatar ?? "",
  //     username,
  //     nickname: data?.nickname ?? "",
  //     roles
  //   });
  // } else {
  //   const avatar =
  //     storageLocal().getItem<DataInfo<number>>(userKey)?.avatar ?? "";
  //   const username =
  //     storageLocal().getItem<DataInfo<number>>(userKey)?.username ?? "";
  //   const nickname =
  //     storageLocal().getItem<DataInfo<number>>(userKey)?.nickname ?? "";
  //   const roles =
  //     storageLocal().getItem<DataInfo<number>>(userKey)?.roles ?? [];
  //   setUserKey({
  //     avatar,
  //     username,
  //     nickname,
  //     roles
  //   });
  // }
}

/** 删除`token`以及key值为`user-info`的localStorage信息 */
export function removeToken() {
  Cookies.remove(TokenKey);
  Cookies.remove(multipleTabsKey);
}

/** 格式化token（jwt格式） */
export const formatToken = (token: string): string => {
  return "Bearer " + token;
};
